import React from "react";

export const FormContainer: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <main className="flex-1 max-w-[30rem] mx-auto space-y-6">
            {children}
        </main>
    )
}

export const FormSection: React.FC<{label?: string, id?: string, children: React.ReactNode}> = ({label, id, children}) => {
    return (
        <div className={"flex flex-col gap-2"} id={id}>
            {label && <div className="block text-md font-medium leading-6 text-gray-900">{label}</div> }
            <div className="flex flex-col gap-4 bg-gray-50 p-4 rounded-lg shadow-sm border border-white">
                {children}
            </div>
        </div>
    )
}

export const FormInputContainer: React.FC<{label?: string, htmlFor?: string, children: React.ReactNode}> = ({label = undefined, htmlFor = undefined, children}) => {
    return (<div className="flex flex-col gap-2">
        {label && <FormInputLabel label={label} htmlFor={htmlFor} />}
        {children}
    </div>)
}

const FORM_INPUT_LABEL_STYLE = "block text-sm font-medium leading-6 text-gray-900";
export const FormInputLabel: React.FC<{label: string, htmlFor?: string}> = ({label, htmlFor = undefined}) => {
    return htmlFor ? <label className={FORM_INPUT_LABEL_STYLE} htmlFor={htmlFor}>{label}</label> :
        <div className={FORM_INPUT_LABEL_STYLE}>{label}</div>
}