import Dashboard from "../../layouts/Dashboard";
import {API_URL} from "../../config";
import {ReportsType} from "../../types/global";
import useResource from "../../hooks/UseResource";
import React, {useCallback, useMemo, useState} from "react";
import {Price} from "../../utils/Price";
import ProductChart from "./components/ProductChart";
import RepChart from "./components/RepChart";
import SourceChart from "./components/SourceChart";
import {DateFormatter} from "../../utils/Datetime";
import {Spinner} from "flowbite-react";
import DateRange from "../../components/Filters/DateRange";
import ComboboxLead from "../../components/Filters/ComboboxLead";
import ComboboxRep from "../../components/Filters/ComboboxRep";
import ListboxSource from "../../components/Filters/ListboxSource";
import useEffectOnChange from "../../hooks/UseEffectOnValueChange";
import {useModelEvent} from "../../hooks/UseModelEvents";
import useRefreshButtonNum from "../../hooks/Dashboard/UseRefreshButtonNum";
import {useTranslation} from "react-i18next";

const Reports = () => {
    const {t} = useTranslation();
    const endpoint = useMemo(() => `${API_URL}/reports`, []);

    const {
        data,
        isPending,
        fetchResource,
    } = useResource<ReportsType>(endpoint);

    const [filters, setFilters] = useState([]);
    const {refreshButtonNum, incrementRefreshButtonNum} = useRefreshButtonNum();

    const handleFilterChange = useCallback((id, value) => {
        setFilters((prev) => {
            const index = prev.findIndex((filter) => filter.id === id);
            if (index === -1) {
                return [...prev, {id, value}]
            }
            return prev.map((filter) => filter.id === id ? {id, value} : filter)
        })
    }, []);

    const handleFilterChangeRep = useCallback((val) => handleFilterChange('rep', val), [handleFilterChange]);
    const handleFilterChangeLead = useCallback((val) => handleFilterChange('lead', val), [handleFilterChange]);
    const handleFilterChangeSource = useCallback((val) => handleFilterChange('source', val), [handleFilterChange]);
    const handleFilterChangeDaterange = useCallback((val) => handleFilterChange('daterange', val), [handleFilterChange]);

    useEffectOnChange(() => {
        const params = {page: 1}
        filters.forEach((filter) => {
            if (filter.value) {
                switch (filter.id) {
                    case 'daterange':
                        params['start'] = DateFormatter.convertToISO8601(filter.value[0]);
                        params['end'] = DateFormatter.convertToISO8601(filter.value[1]);
                        break;
                    case 'lead':
                        params['lead_id'] = filter.value._id;
                        break;
                    case 'rep':
                        params['rep_id'] = filter.value._id;
                        break;
                    case 'source':
                        params['source'] = filter.value;
                        break;
                    default:
                        break;
                }
            }
        })

        fetchResource(params);
    }, [filters, refreshButtonNum]);

    const eventsToListen = useMemo(() => ["OnCreate", "OnDelete", "OnUpdate"], []);
    useModelEvent('Order', eventsToListen, incrementRefreshButtonNum);
    useModelEvent('Lead', eventsToListen, incrementRefreshButtonNum);

    return (<Dashboard heading={t('reports.title')}>
            <div className={"flex flex-row gap-4 flex-wrap border-b pb-6"}>
                <DateRange onChange={handleFilterChangeDaterange} optional={false}/>
                <ComboboxLead onChange={handleFilterChangeLead}/>
                <ComboboxRep onChange={handleFilterChangeRep}/>
                <ListboxSource onChange={handleFilterChangeSource}/>
            </div>
            {
                (!data) ?
                    <div className={"flex justify-center"}><Spinner size="lg" className={"mt-6 mx-auto"}/></div>
                    : <>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                            <div className="bg-white bg-opacity-65 p-6 rounded-xl shadow">
                                <h2 className="text-2xl font-bold text-gray-800 mb-2">${Price.format(data.products.overview.total_revenue_cent, t('currency.symbol'))}</h2>
                                <p className="text-gray-500">{t('reports.sales')}</p>
                            </div>
                            <div className="bg-white bg-opacity-65 p-6 rounded-xl shadow">
                                <h2 className="text-2xl font-bold text-gray-800 mb-2">{data.products.overview.total_quantity}</h2>
                                <p className="text-gray-500">{t('reports.quantity')}</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
                            <div className="lg:col-span-2">
                                <ProductChart products={data.products.products}/>
                            </div>
                            <RepChart reps={data.reps}/>
                            <SourceChart data={data.sources}/>
                        </div>
                    </>
            }
        </Dashboard>
    );
}

export default Reports;