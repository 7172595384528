import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../contexts/AuthContext';
import Dashboard from "../../layouts/Dashboard";
import ComboboxLead from '../../components/Filters/ComboboxLead';
import ComboboxRep from '../../components/Filters/ComboboxRep';
import ListboxStatus from '../../components/Filters/ListboxStatus';
import ListboxSource from '../../components/Filters/ListboxSource';
import {API_URL} from '../../config';
import {LeadType, ProductType, RepType} from '../../types/global';
import {field, getSourceColor, getSourceText} from "../../utils/FormatingUtils";
import Badge from "../../components/Badge";
import {Switch, Transition} from '@headlessui/react';
import {ChevronDownIcon, XCircleIcon} from '@heroicons/react/20/solid';
import useToggle from "../../hooks/UseToggle";
import ComboboxProduct, {ComboboxProductRef} from "../../components/Filters/ComboboxProduct";
import {Avatar, Datepicker} from "flowbite-react";
import {customTheme} from "../../components/Filters/DateRange/customTheme";
import {DateFormatter} from "../../utils/Datetime";
import {useNavigate} from "react-router-dom";
import {usePopupMessage} from "../../contexts/PopupMessageContext";
import {FormContainer, FormInputLabel, FormSection} from "../../components/Form/FormStyles";
import FormButton from "../../components/Form/FormButtom";


const OrderCreate = () => {
    const {t} = useTranslation();
    const {authToken} = useAuth();
    const navigate = useNavigate();
    const {push: pushMessage} = usePopupMessage();

    const [products, setProducts] = useState<{
        product: ProductType[],
        quantity: number,
        price_per_unit: number | string
    }[]>([]);
    const [lead, setLead] = useState<LeadType | null>(null);
    const [rep, setRep] = useState<RepType | null>(null);
    const [status, setStatus] = useState<string>('pending');
    const [source, setSource] = useState<string>('direct');
    const [shouldNotifyInternal, setShouldNotifyInternal] = useState(true)
    const [createdAt, setCreatedAt] = useState(() => {
        return new Date();
    });
    const [internalNote, setInternalNote] = useState('');

    const [isPending, setIsPending] = useState(false);
    const [invalid, setInvalid] = useState({products: false});

    const productsRef = useRef<ComboboxProductRef>(null);

    const handleAddProduct = useCallback((product?: ProductType) => {
        if (product) {
            setInvalid((prevInvalid) => ({...prevInvalid, products: false}));
            productsRef.current?.setSelectedValue(null);
            console.log("product", product)
            setProducts((prevProducts) => [...prevProducts, {product, quantity: 1, price_per_unit: Math.round(product.price / 100, 2)}])
        }
    }, [])

    const handleQuantityChange = useCallback((index: number, newQuantity: number) => {
        setProducts((prevProducts) =>
            prevProducts.map((item, i) =>
                i === index ? {...item, quantity: newQuantity} : item
            )
        );
    }, []);

    const handlePriceChange = useCallback((index: number, newPrice: number | string) => {
        const parsePrice = (price: number | string): number => {
            if (typeof price === 'number') {
                return Number(price.toFixed(2));
            }

            // Replace comma with dot for parsing
            let parsedPrice = price.replace(',', '.');

            // Remove all non-numeric characters except the decimal point
            parsedPrice = parsedPrice.replace(/[^\d.]/g, '');

            // Remove leading zeros, but keep one if it's the only digit before the decimal point
            if (parsedPrice.length > 1 && parsedPrice.startsWith('0') && parsedPrice[1] !== '.') {
                parsedPrice = parsedPrice.slice(1);
            }

            // Ensure only one decimal point
            const parts = parsedPrice.split('.');
            if (parts.length > 2) {
                parsedPrice = parts[0] + '.' + parts.slice(1).join('');
            }

            // Parse to float and limit to 2 decimal places
            const floatPrice = parseFloat(parsedPrice);

            // Check if the result is a valid number
            if (isNaN(floatPrice)) {
                return 0; // or any default value you prefer
            }

            return Number(floatPrice.toFixed(2));
        };

        setProducts((prevProducts) =>
            prevProducts.map((item, i) =>
                i === index ? {...item, price_per_unit: parsePrice(newPrice)} : item
            )
        );
    }, []);

    const handleRemoveProduct = useCallback((index: number) => {
        setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
    }, []);

    // TODO: expecting at least one item

    const submit = useCallback(async (e) => {
        e.preventDefault();

        if (products.length === 0) {
            setInvalid((prevInvalid) => ({...prevInvalid, products: true}));
            // Scroll to element by id products
            window.scrollTo(0, document.getElementById('products').offsetTop, 'smooth');
            return;
        } else {
            setInvalid((prevInvalid) => ({...prevInvalid, products: false}));
        }

        const data = {
            lead_id: lead?._id || null,
            rep_id: rep?._id || null,
            status,
            source,
            items: products.map((product) => ({
                product_id: product.product.product_id,
                quantity: product.quantity,
                price_per_unit: Number(product.price_per_unit) * 100
            })),
            should_notify_internal: shouldNotifyInternal,
            created_at: DateFormatter.convertToISO8601(createdAt),
            internal_note: internalNote
        };

        setIsPending(true);
        const response = await fetch(`${API_URL}/order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(data)
        });
        setIsPending(false);

        const responseData = await response.json();

        if (response.ok) {
            navigate(`/order/${responseData._id}`);
        } else {
            console.error(responseData);
            pushMessage(t('order.create.errorMessage'));
        }
    }, [lead, rep, status, source, products, shouldNotifyInternal, createdAt, internalNote, authToken, navigate, pushMessage, t]);

    return (
        <Dashboard heading={t('order.create.title')}>
            <FormContainer>

                <FormSection label={t('order.create.lead')}>
                        <div>
                            <ComboboxLead onChange={setLead}/>
                        </div>
                        {lead && <ExpandableLeadInfo lead={lead}/>}
                </FormSection>

                <div className={"flex flex-col gap-2 "} id={"products"}>
                    <div className="block text-md font-medium leading-6 text-gray-900">{t('order.create.items')}</div>
                    <div className={`flex flex-col gap-2 bg-gray-50 p-4 rounded-lg shadow-sm border ${invalid.products ? "border-red-500" : "border-white"}`}>

                        {!!products.length && <>
                        <div className={`divide-gray-200 divide-y flex flex-col`}>
                            {products.map((product, index) => (
                                <div className={"py-2.5"} key={index}>
                                    <ProductItem
                                        product={product}
                                        onQuantityChange={(newQuantity) => handleQuantityChange(index, newQuantity)}
                                        onPriceChange={(newPrice) => handlePriceChange(index, newPrice)}
                                        onRemove={() => handleRemoveProduct(index)}
                                    />
                                </div>
                            ))}
                        </div>
                        </>}
                        <div>
                            <ComboboxProduct onChange={handleAddProduct} ref={productsRef}/>
                        </div>
                    </div>
                </div>

                <FormSection label={t('common.details')}>

                        <div className={"flex items-center justify-between"}>
                            <div className="text-sm font-medium text-gray-900">{t('common.date')}</div>
                                <Datepicker
                                    language="sk-SK"
                                    weekStart={1}
                                    theme={customTheme}
                                    labelTodayButton={t('common.today')}
                                    labelClearButton={t('common.clear')}
                                    defaultDate={createdAt}
                                    onSelectedDateChanged={setCreatedAt}
                                />
                            </div>


                        <div className={"flex items-center justify-between"}>
                            <FormInputLabel label={t('common.representative')} />
                            <div>
                                <ComboboxRep onChange={setRep}/>
                            </div>
                        </div>

                        <div className={"flex items-center justify-between"}>
                            <FormInputLabel label={t('common.status')} />
                            <div>
                                <ListboxStatus onChange={setStatus} defaultValue={status} hideNullOption={true} />
                            </div>
                        </div>

                        <div className={"flex items-center justify-between"}>
                            <FormInputLabel label={t('common.category')} />
                            <div>
                                <ListboxSource onChange={setSource} defaultValue={source} hideNullOption={true}/>
                            </div>
                        </div>

                </FormSection>


                <FormSection label={t('order.create.internalInfo')}>

                        <div className={"flex items-center justify-between"}>
                            <FormInputLabel label={t('order.create.sendNotification')} />
                            <div>
                                <Switch
                                    checked={shouldNotifyInternal}
                                    onChange={setShouldNotifyInternal}
                                    className={`${
                                        shouldNotifyInternal ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only">{t('order.create.sendNotification')}</span>
                                    <span
                                        className={`${
                                            shouldNotifyInternal
                                                ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-2"}>
                            <FormInputLabel label={t('common.note')} />

                            <textarea
                                id="internal_note"
                                name="internal_note"
                                placeholder={t('order.create.notePlaceholder')}
                                rows={3}
                                className="block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                                value={internalNote}
                                onChange={(e) => setInternalNote(e.target.value)}
                            />
                        </div>
                </FormSection>

                <FormButton onClick={submit} isPending={isPending} label={t('order.create.createOrder')}/>

            </FormContainer>
        </Dashboard>
    )
}

const ProductItem = ({
                         product, onQuantityChange, onPriceChange, onRemove
                     }) => {
    const {t} = useTranslation();

    useEffect(() => {
        console.log("ProductItem", product)
    }, [product]);
    return (
        <div className="flex items-center gap-2">
            <div className="flex-grow text-sm font-normal">{product.product.name}</div>

            <div className="max-w-24 w-full">
                <label htmlFor="units" className="block text-sm font-medium text-gray-700 mb-1 sr-only">
                    {t('order.create.pieces')}
                </label>
                <div className="relative rounded-lg ">
                    <input
                        type="number"
                        name="units"
                        id="units"
                        value={product.quantity}
                        onChange={(e) => onQuantityChange(parseInt(e.target.value))}
                        className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0"
                        step="1"
                        min="1"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <span className="text-gray-500 sm:text-sm">{t('order.create.pieces')}</span>
                    </div>
                </div>
            </div>

            <div className="max-w-32 w-full">
                <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1  sr-only">
                    {t('order.create.pricePerUnit')}
                </label>
                <div className="relative rounded-lg ">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">{t('currency.symbol')}</span>
                    </div>
                    <input
                        type="number"
                        name="price"
                        id="price"
                        value={product.price_per_unit}
                        onChange={(e) => onPriceChange(e.target.value)}
                        className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="0,00"
                        step="0.01"
                        min="0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <span className="text-gray-500 sm:text-sm">{t('currency.symbol')}</span>
                    </div>
                </div>
            </div>

            <button onClick={onRemove} className="text-red-500 hover:text-red-600 transition-colors flex flex-col justify-center items-center"><XCircleIcon
                className={"w-4 h-4"}/></button>
        </div>
    );
};

const ExpandableLeadInfo = ({lead}: { lead: LeadType }) => {
    const {state: isExpanded, toggle: toggleExpand} = useToggle();
    const {t} = useTranslation();

    const initials = useMemo(() => {
        return lead.name.split(' ').map((word) => word[0]).join('');
    }, [lead]);

    const address = useMemo(() => {
        return field(lead.shipping_address || lead.billing_address);
    }, [lead]);

    return (
        <div className="bg-white p-3 rounded-lg text-sm">
            <button
                onClick={toggleExpand}
                className="flex justify-between items-center w-full"
            >
                <div className={"flex gap-3 items-center"}>
                    <Avatar placeholderInitials={initials} rounded />
                    <div className="flex flex-col justify-start items-start">
                        <div className={"font-medium text-gray-900"}>{field(lead.name)}</div>
                        <div className="text-gray-500">{address}</div>
                    </div>
                </div>
                <ChevronDownIcon
                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                        isExpanded ? 'transform rotate-180' : ''
                    }`}
                />
            </button>

            <Transition
                show={isExpanded}
                enter="transition-opacity duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="mt-3 flex flex-col gap-2">
                    <InfoRow label={t('common.name')} value={field(lead.name)}/>
                    <InfoRow label={t('common.email')} value={field(lead.email)}/>
                    <InfoRow label={t('common.phone')} value={field(lead.phone)}/>
                    <hr/>
                    <InfoRow label={t('common.businessName')} value={field(lead.business_name)}/>
                    <InfoRow label={t('common.billingAddress')} value={field(lead.billing_address)}/>
                    <InfoRow label={t('common.shippingAddress')} value={field(lead.shipping_address)}/>
                    <InfoRow label={t('common.cin')} value={field(lead.cin)}/>
                    <InfoRow label={t('common.tin')} value={field(lead.tin)}/>
                    <InfoRow label={t('common.vatin')} value={field(lead.vatin)}/>
                    <hr/>
                    <InfoRow label={t('common.representative')} value={field(lead.rep?.name)}/>
                    <InfoRow label={t('lead.show.fields.county')} value={field(lead.county)}/>
                    <InfoRow
                        label={t('lead.show.fields.type')}
                        value={
                            lead.source ? (
                                <Badge color={getSourceColor(lead.source)}>
                                    {getSourceText(lead.source)}
                                </Badge>
                            ) : t('common.noData')
                        }
                    />
                </div>
            </Transition>
        </div>
    );
};

const InfoRow = ({label, value}) => (
    <div className="flex gap-4 items-center">
        <div className="leading-6 font-medium text-gray-900">{label}</div>
        <div className="text-gray-700">{value}</div>
    </div>
);

export default OrderCreate;