import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../contexts/AuthContext';
import Dashboard from "../../layouts/Dashboard";
import ComboboxRep from '../../components/Filters/ComboboxRep';
import ListboxSource from '../../components/Filters/ListboxSource';
import {API_URL} from '../../config';
import {CountyType, RepType} from '../../types/global';
import {useNavigate} from "react-router-dom";
import {usePopupMessage} from "../../contexts/PopupMessageContext";
import useForm from "../../hooks/UseForm";
import ComboboxCounty from "../../components/Filters/ComboboxCounty";
import {FormContainer, FormSection} from "../../components/Form/FormStyles";
import InputField from "../../components/Form/InputField";
import FormButton from "../../components/Form/FormButtom";


const LeadCreate = () => {
    const {t} = useTranslation();
    const {authToken} = useAuth();
    const navigate = useNavigate();
    const {push: pushMessage} = usePopupMessage();
    const [isPending, setIsPending] = useState(false);
    const {values, handleChange} = useForm({
        name: "",
        email: "",
        phone: "",
        business_name: "",
        billing_address: "",
        shipping_address: "",
        cin: "",
        tin: "",
        vatin: "",
        note: "",
    });

    const [invalid, setInvalid] = useState({products: false});

    const [rep, setRep] = useState<RepType | null>(null);
    const [county, setCounty] = useState<CountyType | null>(null);
    const [source, setSource] = useState<string>('direct');

    const submit = useCallback(async (e) => {
        e.preventDefault();

        // convert empty strings to null
        const fieldValues = Object.keys(values).reduce((acc, key) => {
            // trim values
            if (typeof values[key] === "string") {
                values[key] = values[key].trim();
            }

            return {
                ...acc,
                [key]: values[key] === "" ? null : values[key]
            }
        }, {});

        const payload = {
            ...fieldValues,
            rep_id: rep?._id || null,
            county: county,
            source: source
        }

        setIsPending(true);
        const response = await fetch(`${API_URL}/lead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })
        setIsPending(false);

        const responseData = await response.json();

        if (response.ok) {
            navigate(`/lead/${responseData._id}`);
        } else {
            pushMessage(responseData.message);
        }

    }, [values, rep, county, source, authToken, pushMessage, navigate]);


    return (
        <Dashboard heading={t('lead.create.title')}>
            <FormContainer>

                <FormSection label={t('lead.create.contactInfo')}>
                    <InputField
                        id="name"
                        name="name"
                        type="text"
                        placeholder={t('common.name')}
                        label={t('common.name')}
                        onChange={handleChange}
                    />
                    <InputField
                        id="email"
                        name="email"
                        type="email"
                        placeholder="lead@example.com"
                        label={t('common.email')}
                        onChange={handleChange}
                    />
                    <InputField
                        id="phone"
                        name="phone"
                        type="tel"
                        placeholder="+421 123 456 789"
                        label={t('common.phone')}
                        onChange={handleChange}
                    />
                </FormSection>


                <FormSection label={t('lead.create.company')}>
                    <InputField
                        id="business_name"
                        name="business_name"
                        type="text"
                        placeholder={t('common.businessName')}
                        label={t('common.businessName')}
                        onChange={handleChange}
                    />
                    <InputField
                        id="billing_address"
                        name="billing_address"
                        type="text"
                        placeholder={t('common.billingAddress')}
                        label={t('common.billingAddress')}
                        onChange={handleChange}
                    />
                    <InputField
                        id="shipping_address"
                        name="shipping_address"
                        type="text"
                        placeholder={t('common.shippingAddress')}
                        label={t('common.shippingAddress')}
                        onChange={handleChange}
                    />

                    <InputField
                        id="cin"
                        name="cin"
                        type="text"
                        placeholder={t('common.cin')}
                        label={t('common.cin')}
                        onChange={handleChange}
                    />
                    <InputField
                        id="tin"
                        name="tin"
                        type="text"
                        placeholder={t('common.tin')}
                        label={t('common.tin')}
                        onChange={handleChange}
                    />
                    <InputField
                        id="vatin"
                        name="vatin"
                        type="text"
                        placeholder={t('common.vatin')}
                        label={t('common.vatin')}
                        onChange={handleChange}
                    />
                </FormSection>

                <FormSection label={t('common.details')}>

                    <div className={"flex items-center justify-between"}>
                        <div className="text-sm font-medium text-gray-900">{t('lead.create.fields.county')}</div>
                        <div>
                            <ComboboxCounty onChange={setCounty}/>
                        </div>
                    </div>


                    <div className={"flex items-center justify-between"}>
                        <div className="text-sm font-medium text-gray-900">{t('common.representative')}</div>
                        <div>
                            <ComboboxRep onChange={setRep}/>
                        </div>
                    </div>

                    <div className={"flex items-center justify-between"}>
                        <div className="text-sm font-medium text-gray-900">{t('common.category')}</div>
                        <div>
                            <ListboxSource onChange={setSource} defaultValue={source} hideNullOption={true}/>
                        </div>
                    </div>

                </FormSection>

                <FormSection label={t('common.note')}>

                                            <textarea
                                                id="note"
                                                name="note"
                                                placeholder={t('lead.create.fields.notePlaceholder')}
                                                rows={3}
                                                className="block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                defaultValue={''}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                                            />

                </FormSection>

                <FormButton onClick={submit} isPending={isPending} label={t('lead.create.submit')}/>

            </FormContainer>
        </Dashboard>
    )
}

export default LeadCreate;