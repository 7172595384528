import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {Price} from "../../../../utils/Price";
import {SourceType} from "../../../../types/global";
import {useTranslation} from 'react-i18next';

const sources = [
    'direct', 'eshop', 'pharmacy', 'distributor'
]

const SourceChart: React.FC<{data: SourceType[]}> = ({ data }) => {
    const { t } = useTranslation();

    const options: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
            background: 'transparent',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '75%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#1f2937',
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '26px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#111827',
                            offsetY: 16,
                            formatter: function (val) {
                                return Price.format(Number(val) * 100, t('currency.symbol'))
                            }
                        },
                        total: {
                            show: true,
                            label: t('reports.sourceChart.total'),
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#4b5563',
                            formatter: function (w) {
                                return Price.format(w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0) * 100, t('currency.symbol'))
                            }
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1
        },
        stroke: {
            width: 2,
            colors: ['#ffffff']
        },
        legend: {
            position: 'bottom',
            offsetY: 0,
            height: 40,
            labels: {
                colors: '#4b5563',
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        labels: data.map(d => t(`reports.sourceChart.sources.${d.source}`)),
        colors: [
            '#3b82f6', '#f59e0b', '#10b981', '#9144ef', '#ec4899',
        ],
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function(value) {
                    return Price.format(value * 100, t('currency.symbol'))
                }
            }
        }
    };

    const series = data.map(r => r.overview.total_revenue_cent / 100);

    return (
        <div className="bg-white bg-opacity-65 p-6 rounded-xl shadow">
            <h2 className="text-xl font-bold text-gray-800 mb-4">{t('reports.sourceChart.title')}</h2>
            <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={350}
            />
        </div>
    );
};

export default SourceChart;