import {InvalidRequestType} from "../types/global";

export const getInvalidFieldsFromResponse = (response: InvalidRequestType): string[] => {
    const invalidFields = new Set<string>();

    if (response?.error_type === 'invalid_request' && Array.isArray(response.data)) {
        for (const item of response.data) {
            if (Array.isArray(item.loc)) {
                for (const loc of item.loc) {
                    invalidFields.add(loc);
                }
            }
        }
    }

    return Array.from(invalidFields);
}