import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid';

interface PaginationProps {
    meta: {
        current_page: number;
        last_page: number;
        per_page: number;
        total: number;
    };
    setPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ meta, setPage }) => {
    const { t } = useTranslation();
    const startItem = (meta.current_page - 1) * meta.per_page + 1;
    const endItem = Math.min(meta.current_page * meta.per_page, meta.total);

    const renderPageNumbers = () => {
        let pages = [];
        for (let i = 1; i <= meta.last_page; i++) {
            if (
                i === 1 ||
                i === meta.last_page ||
                (i >= meta.current_page - 1 && i <= meta.current_page + 1)
            ) {
                pages.push(
                    <a
                        key={i}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setPage(i);
                        }}
                        aria-current={i === meta.current_page ? "page" : undefined}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold no-underline ${
                            i === meta.current_page
                                ? "z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        }`}
                        aria-label={i === meta.current_page ? t('pagination.currentPage', { page: i }) : t('pagination.goToPage', { page: i })}
                    >
                        {i}
                    </a>
                );
            } else if (
                (i === meta.current_page - 2 && meta.current_page > 3) ||
                (i === meta.current_page + 2 && meta.current_page < meta.last_page - 2)
            ) {
                pages.push(
                    <span key={i}
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                        ...
                    </span>
                );
            }
        }
        return pages;
    };

    return (
        <div className="flex items-center justify-between border-t border-gray-200 px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        if (meta.current_page > 1) setPage(meta.current_page - 1);
                    }}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-400 no-underline"
                >
                    {t('pagination.previous')}
                </a>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        if (meta.current_page < meta.last_page) setPage(meta.current_page + 1);
                    }}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-400 no-underline"
                >
                    {t('pagination.next')}
                </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        <Trans i18nKey="pagination.showing">
                            Zobrazených <span className="font-medium">{{ startItem }}</span> do <span className="font-medium">{{ endItem }}</span> z{' '}
                            <span className="font-medium">{{ total: meta.total }}</span> výsledkov
                        </Trans>
                    </p>
                </div>
                <div>
                    <nav aria-label={t('pagination.page')} className="isolate inline-flex -space-x-px rounded-md">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (meta.current_page > 1) setPage(meta.current_page - 1);
                            }}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-400 focus:z-20 focus:outline-offset-0 no-underline"
                        >
                            <span className="sr-only">{t('pagination.previous')}</span>
                            <ChevronLeftIcon aria-hidden="true" className="h-5 w-5"/>
                        </a>
                        {renderPageNumbers()}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (meta.current_page < meta.last_page) setPage(meta.current_page + 1);
                            }}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-400 focus:z-20 focus:outline-offset-0 no-underline"
                        >
                            <span className="sr-only">{t('pagination.next')}</span>
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5"/>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    )
};

export default Pagination;